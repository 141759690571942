.OccupiedStagging-container
{
    /* background-color: black; */
}

.OccupiedStagging-container .card-box
{
    /* padding: 20px; */
    height: 100%;
    
}

.OccupiedStagging-container .card-box .card-para
{
    height: 280px;
    overflow-y: auto;
}

.OccupiedStagging-container .card-box .card-para::-webkit-scrollbar {
    width: 4px!important;
  }
  
  .OccupiedStagging-container .card-box .card-para::-moz-scrollbar {
    width: 4px!important;
  }
  
  /* Handle */
  .OccupiedStagging-container .card-box .card-para::-webkit-scrollbar-thumb {
    background: #2a2a2a;
    transition: .3s;
    border-radius: 3px;
  }
  
  .OccupiedStagging-container .card-box .card-para::-moz-scrollbar-thumb {
    background: #2a2a2a;
    transition: .3s;
    border-radius: 3px;
  }
  
  /* Handle on hover */
  .OccupiedStagging-container .card-box .card-para::-webkit-scrollbar-thumb:hover {
    background: #2a2a2a;
  }
  
  .OccupiedStagging-container .card-box .card-para::-moz-scrollbar-thumb:hover {
    background: #2a2a2a;
  }
  
  /* Track */
  .OccupiedStagging-container .card-box .card-para::-webkit-scrollbar-track {
    transition: .3s;
    background: #e6e6e6;
  }
  
  .OccupiedStagging-container .card-box .card-para::-moz-scrollbar-track {
    transition: .3s;
    background: #e6e6e6;
  }
  /* Track on hover */
  .OccupiedStagging-container .card-box .card-para::-webkit-scrollbar-track:hover {
    transition: .3s;
    background: #e6e6e6;
  }
  
  .OccupiedStagging-container .card-box .card-para::-moz-scrollbar-track:hover {
    transition: .3s;
    background: #e6e6e6;
  }
  
  /* Handle when dragging */
  .OccupiedStagging-container .card-box .card-para::-webkit-scrollbar-thumb:active {
    background: #2a2a2a;
  }
  
  .OccupiedStagging-container .card-box .card-para::-moz-scrollbar-thumb:active {
    background: #2a2a2a;
  }
  
  /* Track when dragging */
  .OccupiedStagging-container .card-box .card-para::-webkit-scrollbar-track:active {
    transition: .3s;
    background: #e6e6e6;
  }
  
  .OccupiedStagging-container .card-box .card-para::-moz-scrollbar-track:active {
    transition: .3s;
    background: #e6e6e6;
  }
  